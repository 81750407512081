@import '../../app/assets/styles/mixins';

.body {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  //height: 100vh;
  // width: 100vw;
  @media (max-width: 768px) {
    height: inherit;
  }
}
.page {
  display: flex;
  flex-direction: column;
  //background: #0c0c0c;
  background-size: cover;
  width: 100%;
  //overflow-y: scroll;
  //padding-bottom: 20px;
  @include scrollbar(8px, #595778, #0c0c0c, 5px, 2px);
}

.row {
  display: flex;
}

