// Convert px to em
$browser-context: 16;

@function em($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }
  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1em;
}

//Custom scrollbar
@mixin scrollbar($size, $thumb-color, $track-color, $thumb-radius: 0, $track-radius: 0) {
  // for Google Chrome
  &::-webkit-scrollbar {
    width: 4px;
    position: absolute;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(166, 174, 203, 10%);
    border-radius: 6px;
  }
  // for Internet Explorer
  & {
    scrollbar-face-color: rgba(166, 174, 203, 10%);
    scrollbar-track-color: transparent;
  }
}

// Truncated text
%truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncated {
  @extend %truncated;
}
